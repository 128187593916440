.section-primary-text {
    color: #b38565;
}

@media (max-width: 576px) {
    .section-primary-text {
        color: #ad886e;
    }

    .course-name-image{
        align-self: self-end;
        z-index: 2;
        
    }

    .vertical-text {
        display: flex;
        flex-direction: column;
    }
    
    .vertical-text span {
        display: block; /* Makes each span take up a whole line */
        margin-bottom: 10px;
        align-self: center;
    }
    
}
.section-home-about {
    background: #243F4D;
}

.home-about-main-button {
    /* align-self: center; */
    align-items: center;
    background-color: transparent !important;
    margin-top: 40px;
    /* width: -webkit-fill-available; */
    width: 40%;
    min-width: 300px;
    display: inline-flex !important;
    justify-content: space-between;
    border-radius: 6px;
    padding: 12px 28px;
    border-color: #ad886e;
    border-width: 2px;
    border-style: solid;
    transition: background-color 0.2s ease 0s;
    font-family: "Caudex-Regular";
    font-size: 18px;
    /* color: #fcd7b6; */
    color: #ad886e;
    span {
        font-family: "Caudex-Regular";
        font-size: 14px;
    };
    
    /* width: 50%; */
}


@media (max-width: 576px) {
    .home-about-main-button {
        width: -webkit-fill-available;
    }
}

.custom-icon-li {
    list-style-type: none; /* Removes default bullet */
    padding-left: 40px; /* Increase as needed to accommodate the icon */
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    margin-bottom: 20px;
}

.custom-icon-li::before {
    content: '';
    position: absolute;
    left: 0; /* Align with the start of the list item */
    top: 8px; /* Align with the top of the list item */
    background: url('/images/icons/feather.svg') no-repeat center center;
    background-size: 20px 20px; /* Adjust the size of the icon */
    width: 24px; /* Width of the icon */
    height: 24px; /* Height of the icon */
    z-index: 1;
}


/* .custom-icon-li {
    list-style-type: none;
    padding-left: 40px; 
    background: url('/images/icons/feather.svg') no-repeat left center;
} */

/* .custom-icon-li::before {
    content: '';
    position: absolute;
    left: 0; 
    top: 50%;
    transform: translateY(-50%) rotate(270deg); 
    background: url('/images/icons/feather.svg') no-repeat center center;
    width: 24px; 
    height: 24px; 
} */
.banner-main-text {
    font-size: 84px;
    margin: 0px 0px 40px 0px;
    font-family: "Caudex-Regular";
}

.banner-second-text {
    color: #a3bbc8;
    font-size: 28px;
    letter-spacing: 0.5px;
    font-family: "DM sans";
    /* padding-bottom: 30px; */
    width: 90%;
}

.banner-main-sitename {
    color: #ad886e;
    font-family: "Caudex-Regular";
}
.banner-section {
    padding-bottom: 10%;
}

.banner-section-box {
    bottom: 50px;
}

.banner-section {
    height: -webkit-fill-available;
}

.banner-main-button {
    /* align-self: center; */
    align-items: center;
    background-color: transparent !important;
    /* width: -webkit-fill-available; */
    width: 40%;
    min-width: 300px;
    display: inline-flex !important;
    justify-content: space-between;
    border-radius: 6px;
    padding: 12px 28px;
    border-color: #ad886e;
    border-width: 2px;
    border-style: solid;
    transition: background-color 0.2s ease 0s;
    font-family: "Caudex-Regular";
    font-size: 18px;
    /* color: #fcd7b6; */
    color: #ad886e;
    span {
        font-family: "Caudex-Regular";
        font-size: 14px;
    };
    /* width: 50%; */
}


@media (min-width: 1024px) {
    .banner-main-text {
        /* background-color: green; */
        font-size: 48px;
        font-family: "Caudex-Regular";
    }
}

@media (min-width: 1440px) {
    .banner-section {
        height: 80vh;
    }

    .banner-main-text {
        font-size: 64px;
        font-family: "Caudex-Regular";
    }
}

@media (min-width: 1920px) {
    .banner-main-text {
        font-size: 64px;
        font-family: "Caudex-Regular";
    }
}


/* @media (max-width: 1440px) {
    .banner-main-text {
        font-size: 64px;
        font-family: "Caudex-Regular";
    }
} */



@media (max-width: 576px) {
    .banner-main-text {
        /* background-color: green; */
        font-size: 32px;
        font-family: "Caudex-Regular";
    }

    .banner-main-sitename {
        color: #ad886e;
        font-size: 32px;
        font-family: "Caudex-Regular";
    }

    .banner-second-text {
        color: #a3bbc8;
        font-size: 20px;
        letter-spacing: 0.5px;
        font-family: "DM sans";
        width: 100%;
        /* padding-bottom: 30px; */
    }

    .banner-main-button {
        width: -webkit-fill-available;
    }
}
/*------ Variables ------*/
:root {
    --base-shade-1: #00a8fa;
    --white-shade-1: #ffffff;
    --black-shade-1: #232323;
    --black-shade-2: #676a8b;
    --heading-font-size-4: 1.8rem;
    /**/

    --header-background-color-mobile: #f0f0f0;
    --header-background-color-tablet: #e0e0e0;
    --header-background-color-desktop: #fff;

    --header-background-button-color-desktop: #ab6838;
    --header-background-button-color-mobile: #AD886E;
}

/*------ Base Styles ------*/
*, *:before, *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
*:not(i) {
    font-family: "Poppins", sans-serif;
}
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

.call-button {
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--header-background-button-color-desktop); /* green background */
    color: white; /* white text */
    padding: 8px 20px; /* padding */
    border: none; /* no border */
    cursor: pointer; /* hand cursor on hover */
    text-decoration: none; /* remove underline */
    border-radius: 5px; /* rounded corners */
    font-size: 16px;
    transition: background-color 0.3s; /* smooth transition for hover effect */
}

.call-button::after {
    content: "";
    display: block;

    width: 0px;
    height: 24px;
    overflow: hidden;
    background-image: url("/images/icons/phone.svg");
    background-size: cover;
    transition: width 200ms, margin-left 200ms;
}

.call-button:is(:hover,:focus)::after {
    width: 24px;
    margin-left: 8px;
}

/*------ Header ------*/
header {
    position: fixed;
    width: 100%;
    padding: 1.2rem 20% 1.2rem 10%;
    z-index: 9999;
    /* height: 76px; */
    /* transition: background-color 0.6s ease, box-shadow 0.6s ease;  */
    transition: background-color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), box-shadow 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);

}
header nav {
    height: 48px;
}
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
nav ul {
    list-style: none;
    display: flex;
    gap: 5rem;
    align-items: center;
}
nav a {
    font-size: var(--heading-font-size-4);
    text-decoration: none;
}
.logo {
    height: 44px;
    cursor: pointer;
}
nav ul a {
    color: var(--white-shade-1);
}
#ham-menu {
    display: none;
}
.dn {
    display: none;
}
nav ul.opened {
    left: 0;
}
.sticky {
    background-color: rgba(36, 63, 77, 0.9); 
    /* backdrop-filter: blur(10px);  */
    backdrop-filter: blur(15px) saturate(125%) brightness(90%);
    box-shadow: 0 2rem 1.5rem rgba(0, 0, 0, 0.1);
}

.sticky nav ul a, .sticky nav a#logo, .sticky #ham-menu {
    color: var(--black-shade-1);
}
nav ul a:hover {
    /* color: var(--black-shade-1); */
}
.sticky nav ul a:hover {
    color: var(--base-shade-1);
}

@media only screen and (max-width: 767px) {
    html {
        font-size: 100%;
        scroll-behavior: smooth;
    }

    header {
      padding: 0.9rem 4rem;
    }
    #ham-menu {
      display: block;
      color: var(--white-shade-1);
    }
    nav ul {
      background-color: var(--black-shade-1);
      position: fixed;
      left: -100vw;
      top: 70.4px;
      height: calc(100vh - 70.4px);
      width: 100vw;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      transition: 0.6s;
      /* transition: all 0.4s ease-out; */
      gap: 0;
    }
    nav ul a {
      font-size: var(--heading-font-size-3);
    }
    .sticky nav ul a {
      color: var(--white-shade-1);
    }
    nav ul a:hover {
      color: var(--base-shade-1);
    }
    .fas {
        display: initial;
    }

     /* my changes from here applying on both tablet and mobile */
    header nav {
        flex-direction: row-reverse;
    }
    .hamburger-react {
        z-index: 3;
    }
    nav ul {
        top: 0px;
        height: 100vh;
    }
}

@media only screen and (max-width: 575px) {
    html {
        font-size: 100%;
        scroll-behavior: smooth;
    }
    header {
      padding: 0.25rem 1.2rem;
      height: unset;
    }
    
    nav ul {
      /* top: 56.375px;
      height: calc(100vh - 56.375px); */
    }
    .fas {
        display: initial;
    }

    nav ul {
        background-image: url("/images/background/mobile2.png");
        background-size: cover;
        padding-top: 48px;
    }
}

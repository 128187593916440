:root {
    --body-color: rgb(250, 250, 250);
    --color-white: rgb(255, 255, 255);
    --text-color-second: rgb(68, 68, 68);
    --text-color-third: rgb(30, 159, 171);
    --first-color: #00996d;
    --first-color-hover: rgb(40, 91, 212);
    --second-color: rgb(110, 87, 224);
    --third-color: rgb(192, 166, 49);
    --first-shadow-color: rgba(0, 0, 0, 0.1);
}

.nav-link {
    text-decoration: none;
    color: var(--body-color);
    font-weight: 500;
    /* padding-inline: 15px;
    margin-inline: 20px; */
    cursor: pointer;
}

.nav-link:hover {
    color: var(--first-color);
}

.active-link {
    position: relative;
    color: var(--first-color);
    transition: .3;
}

.active-link::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translate(-50%, -50%);
    width: 5px;
    height: 5px;
    background: var(--first-color);
    border-radius: 50%;
}
.classic-link {
    position: relative;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding-bottom: 2px; 
    overflow: hidden;
    vertical-align: middle;
}

.underline {
    display: block;
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, #66FF66, #009900); /* A smooth gradient from light green to dark green */
    transition: width 0.3s ease;
}

.classic-link:hover .underline {
    width: 100%;
}

.text-numbers {
    color: #e6f5fc;
}

.image-mask {
    position: absolute;
    /* width: 312px; */
    width: inherit;
    height: 405.52914285714286px;
    /* background: linear-gradient(180deg, #0029FF 0%, rgba(255, 0, 229, 0.24) 100%); */
    /* background: linear-gradient(180deg, #fcd7b6 0%, rgba(173, 136, 110, 0.24) 100%); */
    background: linear-gradient(180deg, #436372 0%, rgba(67, 99, 114, 0.24) 100%);
    opacity: .3;
    bottom: -0.20094285714283444px;
    top: 0px;
    right: auto;
    /* left: 0px; */
    right: 20px;
    border-radius: 0 10px 0 10px;
}

.box-image {
    margin-top: 20px;
}

@media (max-width: 576px) {
    .text-numbers {
        color: #e1f4fc;
    }
}
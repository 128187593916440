.slick-slider {
    margin-top: 30px;
    }
    
    .slider-item {
    /* margin: 0 50px !important; */
        text-align: -webkit-center;
        width: 90% !important; 
    }
    
    .slick-next {
        right: -15px;
        color: black;
    }
    
    .slick-prev {
        left: -15px;
        color: black;
    }
    
    .slick-next:before, .slick-prev:before {
        color: coral;  /* or any other color that you want */
    }
    